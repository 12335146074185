import { ReactElement } from 'react';

import { FinancialTransaction } from 'modules/accounts/types';
import {
  ORDER,
  PageInfo,
  QueryFilterParams,
  Sorting,
  SCANNING_DEVICE_TYPE,
  SearchAndFiltersState,
  UserInfo,
  LogInfo,
  NoteData,
} from 'modules/common';
import { BAG_QUALITY_ISSUE, LocationItem } from 'modules/locations';
import { UserDetails } from 'modules/users';

export interface BagsDepositedTableEntry {
  bagId: string;
  acceptedLocation: string;
  acceptedAt: string;
  acceptedUnit: string;
  receivedLocation: string;
  receivedAt: string;
  processedLocation: string;
  processedAt: string;
  creditedAt: string;
  status: string;
  bagQuality: ReactElement | string;
  totalAmount: string;
  user: string;
}

export enum BAG_DEPOSITED_STATUS {
  DROPPED = 'dropped',
  RECEIVED = 'received',
  PROCESSED = 'processed',
  CREDITED = 'credited',
}

export interface BagsDepositedUser {
  userUId: string;
  firstName?: string;
  lastName?: string;
}

export enum VOUCHER_TYPE {
  PERCENTAGE = 'PERCENTAGE',
  FIXED_AMOUNT = 'FIXED_AMOUNT',
}

export enum VOUCHER_APPLY_EVENT {
  BAG_PROCESSING = 'BAG_PROCESSING',
  CUSTOMER_INPUT = 'CUSTOMER_INPUT',
}

export enum VOUCHER_APPLIER {
  CUSTOMER = 'CUSTOMER',
  ADMIN = 'ADMIN',
  SYSTEM = 'SYSTEM',
}

export enum VOUCHER_APPLIED_TO {
  PROCESSING_FEE = 'PROCESSING_FEE',
  CALCULATED_AMOUNT = 'CALCULATED_AMOUNT',
  FULL_PRICE = 'FULL_PRICE',
}

export interface DroppedBagVoucher {
  code: string;
  name: string;
  type: VOUCHER_TYPE;
  amount: number;
  totalCredited: number;
  applyEvent: VOUCHER_APPLY_EVENT;
  applier: VOUCHER_APPLIER;
  appliedTo: VOUCHER_APPLIED_TO;
  appliedAt: Date;
}

export interface BagsDepositedItem {
  _id: string;
  bagId: string;
  bagFee: number;
  createdAt: string;
  deviceId: string;
  type: string;
  dropOffLocation: LocationItem;
  issues: string[];
  items: BagItems[];
  qualityIssues: BAG_QUALITY_ISSUE[];
  lat: string;
  lon: string;
  organizationUId: string;
  receivedLocation: LocationItem;
  receivedAt: string;
  processedLocation: LocationItem;
  processedAt: string;
  creditedAt?: string;
  status: BAG_DEPOSITED_STATUS;
  updatedAt: string;
  totalAmount: number;
  user?: BagsDepositedUser;
  noteData?: NoteData;
  voucherRedemptions?: DroppedBagVoucher[];
}

export enum BAG_ITEM_PRICE_TYPE {
  CRV = 'crv',
  SRP = 'srp',
  FIVE_CENT = 'five_cent',
  TEN_CENT = 'ten_cent',
  TWENTY_FIVE_CENT = 'twenty_five_cent',
}

interface BagItemAmountDetails {
  amount: number;
  calculationPrice: number;
  fiveCentExchangeRate: number | null;
  piecesCount: number;
  priceType: BAG_ITEM_PRICE_TYPE;
  statePrice: number;
  weight: number;
}

interface BagItemInComingdata {
  weight: number;
  netWeight: number;
  barrel: number;
}

export interface BagItemAmountDetailsWithType extends BagItemAmountDetails {
  type: string;
  intendedPriceType: string;
}

export interface BagItems {
  amountDetails: BagItemAmountDetails[];
  priceType: BAG_ITEM_PRICE_TYPE;
  type: string;
  weight: number;
  itemId: string;
  imagePath: string;
  image: string;
  fullPath: string;
  inComingData: BagItemInComingdata;
}

export interface BagsDepositedListQueryParams {
  page?: number;
  sort?: BAGS_DEPOSITED_SORT_OPTIONS;
  order?: ORDER;
  filters?: QueryFilterParams;
  operatorUId?: string;
}

export interface BagsDepositedList {
  meta: PageInfo;
  data: BagsDepositedItem[];
}

export enum BAGS_DEPOSITED_SORT_OPTION_TABLE_KEYS {
  ACCEPTED_AT = 'acceptedAt',
  RECEIVED_AT = 'receivedAt',
  PROCESSED_AT = 'processedAt',
  CREDITED_AT = 'creditedAt',
}

export enum BAGS_DEPOSITED_SORT_OPTIONS {
  CREATED_AT = 'createdAt',
  RECEIVED_AT = 'receivedAt',
  PROCESSED_AT = 'processedAt',
  CREDITED_AT = 'creditedAt',
}

export const bagsDepositedTableSortOptions: Record<
  BAGS_DEPOSITED_SORT_OPTION_TABLE_KEYS,
  BAGS_DEPOSITED_SORT_OPTIONS
> = {
  acceptedAt: BAGS_DEPOSITED_SORT_OPTIONS.CREATED_AT,
  receivedAt: BAGS_DEPOSITED_SORT_OPTIONS.RECEIVED_AT,
  processedAt: BAGS_DEPOSITED_SORT_OPTIONS.PROCESSED_AT,
  creditedAt: BAGS_DEPOSITED_SORT_OPTIONS.CREDITED_AT,
};

export type BagsDepositedListSorting = Sorting<BAGS_DEPOSITED_SORT_OPTION_TABLE_KEYS>;
export interface BagsDepositedListState {
  page: number;
  sorting: BagsDepositedListSorting;
  searchAndFilters: SearchAndFiltersState;
}

export interface DropOffSessionsTableEntry {
  sessionId: string;
  sessionStart: string;
  sessionEnd: string;
  deviceType: string;
  deviceID: string;
  customerId: string;
  customerName: string;
  bagsDeposited: number;
  issues: number;
}

export enum DROP_OFF_SESSIONS_SORT_OPTION_TABLE_KEYS {
  SESSION_START = 'sessionStart',
}

export enum DROP_OFF_SESSIONS_SORT_OPTIONS {
  STARTED_AT = 'startedAt',
}

export const dropOffSessionsTableSortOptions: Record<
  DROP_OFF_SESSIONS_SORT_OPTION_TABLE_KEYS,
  DROP_OFF_SESSIONS_SORT_OPTIONS
> = {
  sessionStart: DROP_OFF_SESSIONS_SORT_OPTIONS.STARTED_AT,
};

export type DropOffSessionListSorting = Sorting<DROP_OFF_SESSIONS_SORT_OPTION_TABLE_KEYS>;

export interface DropOffSessionsState {
  page: number;
  sorting: DropOffSessionListSorting;
}

export enum DropOffSessionStatus {
  CREATED = 'created',
  DONE = 'done',
  CLOSED = 'closed',
  IN_PROGRESS = 'in_progress',
}

export interface DropOffSessionUser {
  userUId: string;
  firstName: string;
  lastName: string;
}

export interface DropOffSessionItem {
  bagSessionId: string;
  deviceType: SCANNING_DEVICE_TYPE;
  deviceId: string;
  qualityIssuesCount: number;
  bagDepositedCount: number;
  startedAt: string;
  endedAt: string;
  status: DropOffSessionStatus;
  user?: DropOffSessionUser;
}

export interface DropOffSessionList {
  meta: PageInfo;
  data: DropOffSessionItem[];
}

export interface DropOffSessionsByOperatorUIdListQueryParams {
  operatorUId?: string;
  deviceId?: string;
  page?: number;
  take?: number;
  order?: ORDER;
  sort?: DROP_OFF_SESSIONS_SORT_OPTIONS;
}

export enum DROPPED_BAG_ISSUE_TYPE {
  MISSING_BAG_ID = 'missing_bag_id',
  NOT_VALID_ID = 'not_valid_id',
  BAG_HAS_BEEN_PROCESSED = 'bag_has_been_processed',
  BAG_ID_NOT_FOUND = 'bag_id_not_found',
}

export interface DropOffSessionDroppedBag {
  _id: string;
  bagDeposited: string;
  bagUId: string;
  issues: DROPPED_BAG_ISSUE_TYPE[];
  qualityIssues: BAG_QUALITY_ISSUE[];
  bagId?: string;
}

export interface DropOffSession {
  deviceId: string;
  deviceType: SCANNING_DEVICE_TYPE;
  bagSessionId: string;
  customer: UserDetails;
  burntInCustomerData: DropOffSessionUser;
  droppedBags: DropOffSessionDroppedBag[];
  startedAt: string;
  endedAt: string;
}

export interface ScanningDeviceQueryParams {
  operatorUId?: string;
  page?: number;
  take?: number;
  order?: ORDER;
  sort?: DROP_OFF_SESSIONS_SORT_OPTIONS;
}

export interface ScanningDeviceList {
  meta: PageInfo;
  data: DropOffSessionItem[];
}

export enum MONEY_TRANSFERS_SORT_OPTION_TABLE_KEYS {
  TIMESTAMP = 'timestamp',
}

export enum MONEY_TRANSFERS_SORT_OPTIONS {
  CREATED_AT = 'createdAt',
}

export const moneyTransfersTableSortOptions: Record<
  MONEY_TRANSFERS_SORT_OPTION_TABLE_KEYS,
  MONEY_TRANSFERS_SORT_OPTIONS
> = {
  timestamp: MONEY_TRANSFERS_SORT_OPTIONS.CREATED_AT,
};

export type MoneyTransfersListSorting = Sorting<MONEY_TRANSFERS_SORT_OPTION_TABLE_KEYS>;

export interface MoneyTransfersByOperatorUIdListQueryParams {
  operatorUId?: string;
  page?: number;
  take?: number;
  order?: ORDER;
  sort?: MONEY_TRANSFERS_SORT_OPTIONS;
  filters?: QueryFilterParams;
}

export interface MoneyTransfersState {
  page: number;
  sorting: MoneyTransfersListSorting;
  searchAndFilters: SearchAndFiltersState;
}

export interface MoneyTransferItem {
  id: number;
  createdAt: string;
  userUId: string;
  userInfo: UserInfo;
  amount: number;
  status: string;
  service: string;
  payoutAddressType: string;
  payoutAddress: string;
  organizationUId: string;
}
export interface MoneyTransfersList {
  meta: PageInfo;
  data: MoneyTransferItem[];
}

export interface MoneyTransfersTableEntry {
  transferId: number;
  timestamp: string;
  customerId: string;
  customerName: string;
  amount: string;
  destination: string;
  destinationType: string;
  status: string;
}

export interface BagDepositedUserInfo {
  email: string;
  name: string;
  pendingBags: number;
  totalBags: number;
  totalPaidValue: number;
  userUId: string;
}

export interface BagsDepositedDetailsType {
  droppedBag: BagsDepositedItem;
  userInfo?: BagDepositedUserInfo;
}

export interface BagsDepositedNoteUpdateRequest {
  id: string;
  body: {
    noteData?: NoteData;
  };
}

export interface BagContentType {
  data?: BagsDepositedItem;
}

export enum PayoutStatus {
  CREATED = 'CREATED',
  SELECTED_FOR_PAYOUT = 'SELECTED_FOR_PAYOUT',
  SENDING = 'SENDING',
  SENT = 'SENT',
  PROCESSING = 'PROCESSING',
  FAILED = 'FAILED',
  SUCCESS = 'SUCCESS',
}

export enum BagPurchaseStatus {
  CREATED = 'CREATED',
  READY_TO_PAY = 'READY_TO_PAY',
  PAYMENT_IN_PROGRESS = 'PAYMENT_IN_PROGRESS',
  FAILED = 'FAILED',
  CANCELED = 'CANCELED',
  PAID = 'PAID',
}

export interface BagPurchaseItem {
  status: BagPurchaseStatus;
  id: number;
  deviceType: SCANNING_DEVICE_TYPE;
  deviceId: string;
  chargedAmount: number;
  bagPrice: number;
  bagCost: number;
  acceptedAt: string;
  creditedAt: string;
  createdAt: string;
  updatedAt: string;
  organizationUId: string;
  userUId: string;
  quantity: number;
  userInfo: UserInfo;
  transactionDetails?: FinancialTransaction;
}

export enum BAG_PURCHASE_HISTORY_TYPES {
  COMMENT = 'COMMENT',
  STATUS_CHANGE = 'STATUS CHANGE',
}

export interface BagPurchaseHistory {
  id: number;
  type: BAG_PURCHASE_HISTORY_TYPES;
  note?: string;
  userUId?: string;
  createdAt: string;
}

export interface BagsPurchasedDetails extends BagPurchaseItem {
  bagPurchaseHistory?: BagPurchaseHistory[];
}

export interface BagsPurchasedList {
  meta: PageInfo;
  data: BagPurchaseItem[];
}

export enum BAGS_PURCHASED_LIST_SORT_OPTION_TABLE_KEYS {
  TIMESTAMP = 'timestamp',
  BAG_PURCHASE_ID = 'bagPurchaseId',
  DEVICE_ID = 'deviceId',
  COSTUMER_ID = 'customerId',
}

export enum BAGS_PURCHASED_LIST_SORT_OPTIONS {
  CREATED_AT = 'createdAt',
  ID = 'id',
  DEVICE_ID = 'deviceID',
  USER_UID = 'userUId',
}

export const bagsPurchasedListTableSortOptions: Record<
  BAGS_PURCHASED_LIST_SORT_OPTION_TABLE_KEYS,
  BAGS_PURCHASED_LIST_SORT_OPTIONS
> = {
  timestamp: BAGS_PURCHASED_LIST_SORT_OPTIONS.CREATED_AT,
  bagPurchaseId: BAGS_PURCHASED_LIST_SORT_OPTIONS.ID,
  deviceId: BAGS_PURCHASED_LIST_SORT_OPTIONS.DEVICE_ID,
  customerId: BAGS_PURCHASED_LIST_SORT_OPTIONS.USER_UID,
};

export type BagsPurchasedListSorting = Sorting<BAGS_PURCHASED_LIST_SORT_OPTION_TABLE_KEYS>;

export interface BagsPurchasedListState {
  page: number;
  sorting: BagsPurchasedListSorting;
  userUId?: string;
}

export interface BagsPurchasedListQueryParams {
  operatorUId?: string;
  deviceId?: string;
  userUId?: string;
  page?: number;
  take?: number;
  order?: ORDER;
  sort?: BAGS_PURCHASED_LIST_SORT_OPTIONS;
}

export interface BagDepositedLogInfo extends LogInfo<BagsDepositedItem> {
  id: string;
}

export interface BagDepositedLogInfoList {
  data: BagDepositedLogInfo[];
  meta: PageInfo;
}

export interface BagDepositedLogsListState {
  page: number;
  bagDepositedLogs: BagDepositedLogInfo[];
}

export interface DropOffSessionLogInfo extends LogInfo<DropOffSessionItem> {
  id: string;
}

export interface DropOffSessionLogInfoList {
  data: DropOffSessionLogInfo[];
  meta: PageInfo;
}

export interface DropOffSessionLogsListState {
  page: number;
  dropOffSessionLogs: DropOffSessionLogInfo[];
}

interface PayoutRequestOfPayout {
  status: string;
  createdAt: string;
  updatedAt: string;
}
export interface TransferHistoryItem {
  id: number;
  type: string;
  note: string;
  createdAt: string;
  causerUserUId: string;
}

export interface MoneyTransferDetail {
  id: number;
  organizationUId: string;
  userUId: string;
  service: string;
  payoutAddressType: string;
  payoutAddress: string;
  status: PayoutStatus;
  amount: number;
  createdAt: string;
  updatedAt: string;
  history: TransferHistoryItem[];
  payoutRequest: PayoutRequestOfPayout;
  userInfo: UserInfo;
}
