export const DEVICES_API_URLS = {
  DEVICES_LIST: '/scanning-device/list',
  DEVICES_ADD: '/scanning-device',
  DEVICE_BY_ID: (id: string) => `/scanning-device/${id}`,
  UPDATE_DEVICE_ACCESS_CODE_BY_ID: (id: string) => `/scanning-device/${id}/access-code`,
  ADD_NOTE: '/scanning-device/add-note',
  ACCESS_CODE: (deviceId: string) => `/scanning-device/${deviceId}/access-code`,
  AUTHORIZE: (deviceId: string) => `/scanning-device/${deviceId}/authorize`,
  BLOCK: (deviceId: string) => `/scanning-device/${deviceId}/block`,
  REFRESH_TOKEN: '/scanning-device/refresh-tokens',
  DEVICE_LOG_INFO: '/admin/scanning-device-log-info',
};

export const CONTAINER_BOX_API_URLS = {
  WAITING_LIST_LENGTH: (containerBoxUId: string) =>
    `/container-box/waiting-list-length/${containerBoxUId}`,
};
