import { formatInTimeZone } from 'date-fns-tz';

export const AMERICA_LOS_ANGELES = 'America/Los_Angeles';
export const now = () => new Date();

export const startOfTheDay = (dateParam: Date = now()) => {
  const date = new Date(dateParam);
  date.setUTCHours(0, 0, 0, 0);

  return date;
};

export const startOfTheLADay = (dateParam: Date = now()) => {
  const date = new Date(dateParam.toISOString());
  date.setUTCHours(7, 0, 0, 0);

  return date;
};

export const endOfTheDay = (dateParam: Date = new Date()) => {
  const date = new Date(dateParam);
  date.setUTCHours(23, 59, 59, 999);

  return date;
};

export const calculateDateBack = (amountOfDaysBack: number, dateParam = now()) => {
  const date = new Date(dateParam);
  date.setDate(dateParam.getDate() - amountOfDaysBack);
  date.setUTCHours(0, 0, 0, 0);
  return date;
};

export const calculateDateBackLA = (amountOfDaysBack: number, dateParam = now()) => {
  const date = new Date(dateParam);
  date.setDate(dateParam.getDate() - amountOfDaysBack);
  date.setUTCHours(7, 0, 0, 0);

  return date;
};

export const calculateDayForward = (amountOfDaysForward: number, dateParam = now()) => {
  const date = new Date(dateParam);
  date.setDate(dateParam.getDate() + amountOfDaysForward);
  date.setUTCHours(0, 0, 0, 0);
  return date;
};

export const calculateDayForwardLA = (amountOfDaysForward: number, dateParam = now()) => {
  const date = new Date(dateParam);
  date.setDate(dateParam.getDate() + amountOfDaysForward);
  date.setUTCHours(7, 0, 0, 0);
  return date;
};

export const toLocaleDateString = (
  date?: string,
  valueIfError = '',
  timezone: string = AMERICA_LOS_ANGELES,
  format = 'M/d/yyyy',
) => (date ? formatInTimeZone(date, timezone, format) : valueIfError);

type ToLocaleDateAndTimeStringOptions = {
  valueIfError?: string;
  timezone?: string;
  format?: string;
};

const toLocaleDateAndTimeStringDefaultOptions: Required<ToLocaleDateAndTimeStringOptions> = {
  valueIfError: '',
  timezone: AMERICA_LOS_ANGELES,
  format: 'M/d/yyyy, h:mmaaa',
};

export const toLocaleDateAndTimeString = (
  date?: string,
  options?: ToLocaleDateAndTimeStringOptions,
) => {
  const mergedOptions = {
    ...toLocaleDateAndTimeStringDefaultOptions,
    ...options,
  };
  if (!date) return mergedOptions.valueIfError;
  return formatInTimeZone(date, mergedOptions.timezone, mergedOptions.format);
};
