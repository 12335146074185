import { LogInfo, NoteData, ORDER, PageInfo, QueryFilterParams, Sorting } from 'modules/common';
import { ContainerBoxForDashboard } from 'modules/dashboard';
import { DeviceItem } from 'modules/devices';
import { Address } from 'modules/locations';

export enum CONTAINER_BOX_STATUS {
  ACTIVE = 'active',
  SUSPENDED = 'suspended',
  ARCHIVE = 'archive',
}

export enum CONTAINER_BOX_HISTORY_TYPE {
  COMMENT = 'comment',
  STATUS_CHANGE = 'status_change',
}

export enum CONTAINER_COMMAND {
  OPEN_DOOR = 'OPEN_DOOR',
  CHANGE_TO_WORKING_MODE = 'CHANGE_TO_WORKING_MODE',
  CHANGE_TO_MAINTENANCE_MODE = 'CHANGE_TO_MAINTENANCE_MODE',
}

export interface ContainerBoxesListQueryParams {
  page?: number;
  take?: number;
  sort?: CONTAINER_BOXES_LIST_SORT_OPTIONS;
  order?: ORDER;
  status?: CONTAINER_BOX_STATUS | string;
  operatorUId?: string;
  containerBoxUIdSearch?: string;
  filters?: QueryFilterParams;
}

export enum CONTAINER_BOXES_LIST_SORT_OPTION_TABLE_KEYS {
  IN_SERVICE = 'inService',
}

export enum CONTAINER_BOXES_LIST_SORT_OPTIONS {
  CREATED_AT = 'createdAt',
}
export type ContainerBoxesListSorting = Sorting<CONTAINER_BOXES_LIST_SORT_OPTION_TABLE_KEYS>;

export const containerBoxesListSortOptions: Record<
  CONTAINER_BOXES_LIST_SORT_OPTION_TABLE_KEYS,
  CONTAINER_BOXES_LIST_SORT_OPTIONS
> = {
  inService: CONTAINER_BOXES_LIST_SORT_OPTIONS.CREATED_AT,
};

export interface ContainerBoxesListState {
  page: number;
  sorting: ContainerBoxesListSorting;
}

export interface ContainerBoxItem {
  _id: string;
  name: string;
  containerBoxUId: string;
  locationUId: string;
  organizationUId: string;
  createdAt: string;
  locationAddress: string;
  locationZip: string;
  locationCity: string;
  status: CONTAINER_BOX_STATUS;
  doorsQuantity: number;
  doorsDown: number;
  usersConnected: number;
  totalSessionsToday: number;
  waitingListStatus?: WAITING_LIST_STATUS;
}

interface UserUIdWithNames {
  userUId: string;
  firstName: string;
  lastName: string;
}

export interface ContainerBox extends ContainerBoxItem {
  scanningDevices: DeviceItem[];
  noteData?: NoteData;
}
export interface ContainerBoxDetailsData extends ContainerBox {
  waitingTickets: WaitingTicketItem[];
  waitingListStatus: WAITING_LIST_STATUS;
}

export interface ContainerBoxesList {
  meta: PageInfo;
  data: ContainerBoxItem[];
}

export interface ContainerBoxAccessCodeChange {
  accessCode?: string;
}

export interface ContainerBoxChange extends ContainerBoxAccessCodeChange {
  name?: string;
  status?: CONTAINER_BOX_STATUS;
  locationUId?: string;
  locationForRTK?: Address;
  deviceIds?: string[];
  waitingListStatus?: WAITING_LIST_STATUS;
  noteData?: NoteData;
}

export interface UpdateContainerBoxBody extends ContainerBoxChange {
  id: string;
}

export interface UpdateContainerBoxAccessCodeBody extends ContainerBoxAccessCodeChange {
  id: string;
}

export interface UpdateContainerBoxResponse {
  containerBoxUId: string;
  name: string;
  locationUId: string;
  organizationUId: string;
  status: CONTAINER_BOX_STATUS;
  blockedAt: string;
}

export enum ADD_CONTAINER_BOX_FIELDS {
  NAME = 'name',
  ORGANIZATION_UID = 'organizationUId',
  STATUS = 'status',
  LOCATION_UID = 'locationUId',
  DEVICE_IDS = 'deviceIds',
  NOTE_DATA = 'noteData',
}

export interface AddNewContainerBoxFields {
  name: string;
  organizationUId?: string;
  locationUId?: string;
  status: CONTAINER_BOX_STATUS;
  deviceIds: string[];
  noteData?: string;
}
export interface UpdateContainerBoxFields {
  locationUId?: string;
  status?: CONTAINER_BOX_STATUS;
  accessCode?: string;
}
export interface AddNewContainerBoxBody {
  name: string;
  locationUId?: string;
  status: CONTAINER_BOX_STATUS;
  deviceIds: string[];
  noteData?: string;
}

export interface KickOffWaitingRoomBody {
  userUId: string;
  containerBoxUId: string;
}

export interface KickOffContainerRoomBody {
  userUId: string;
  bagSessionId: string;
}

export interface AddNewContainerBoxResponse {
  name: string;
  locationUId: string;
  organizationUId: string;
  status: CONTAINER_BOX_STATUS;
}

export interface ContainerBoxLogInfo extends LogInfo<ContainerBox> {
  id: string;
}

export interface ContainerBoxLogInfoList {
  data: ContainerBoxLogInfo[];
  meta: PageInfo;
}

export interface ContainerBoxLogsListState {
  page: number;
  containerBoxLogs: ContainerBoxLogInfo[];
}

export enum WAITING_LIST_STATUS {
  OPEN = 'open',
  CLOSED = 'closed',
}

// TODO QD-1726
export enum DOOR_STATUS {
  OK = 'ok',
  CLOSED = 'closed',
}

export type WaitingTicketItem = {
  id: string;
  user: UserUIdWithNames;
  waitingTicket: string;
  validAt: string;
};

export enum CONNECT_TYPE {
  JOIN = 'JOIN',
  LEAVE = 'LEAVE',
}

export interface WaitingQueueData {
  type: CONNECT_TYPE;
  affectedWaitingTicket: string;
  containerBoxUId: string;
  waitingQueue: WaitingTicketItem[];
  isLoading: boolean;
  error?: string;
}
export interface WaitingQueueState {
  data: WaitingQueueData;
}

export interface DashboardContainerBoxesState {
  data: ContainerBoxForDashboard[];
}

export interface DashboardContainerDoorBagDepositedChange {
  deviceId: string;
  value: number;
}

export interface DashboardContainerBoxWaitingRoomChange {
  containerBoxUId: string;
  value: number;
}

export interface ContainerCommandRequestDTO {
  command: CONTAINER_COMMAND;
  deviceUId: string;
}

export interface ContainerCommandDTO extends ContainerCommandRequestDTO {
  transactionUId: string;
  messageId: string;
  sentAt: number;
}
