import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { io } from 'socket.io-client';
import { useIsFirstRender } from 'usehooks-ts';

import i18n from 'config/translation';
import { getAccessToken, getIsLoggedIn } from 'modules/auth';
import { EVENTS } from 'modules/common/types';
import {
  DashboardContainerBoxWaitingRoomChange,
  DashboardContainerDoorBagDepositedChange,
  getWaitingRoomData,
  WaitingQueueState,
} from 'modules/container-boxes';
import {
  updateBagsDepositedOnScanner,
  updateQueueOnDashboardContainerBox,
} from 'modules/container-boxes/store';
import {
  setContainerScannerAvailableData,
  setContainerScannerKickOutUserData,
  setContainerScannerStateUpdateData,
  setContainerScannerWelcomeDropOffData,
} from 'modules/container-boxes/store/containerScannerStore';
import {
  ContainerScannerAvailabilityUpdate,
  ContainerScannerBaseUpdate,
  ContainerScannerResponseError,
  ContainerScannerStateUpdateIncoming,
  ContainerScannerWelcomeDropOffData,
  SCANNER_STATE,
} from 'modules/devices';
import { addToEvents } from 'modules/export';
import { ExportEvent } from 'modules/export/types';

import { useAppDispatch } from './useAppDispatch';
import { useAppSelector } from './useAppSelector';

export const useWebsocket = () => {
  const accessToken = useAppSelector(getAccessToken);
  const isLoggedIn = useAppSelector(getIsLoggedIn);
  const dispatch = useAppDispatch();
  const isFirstRender = useIsFirstRender();

  const URL = import.meta.env.VITE_APP_API_URL;

  const generateSocketIObody = (token?: string) => ({
    autoConnect: false,
    auth: {
      token: `Bearer ${token}`,
    },
    withCredentials: true,
    transports: ['websocket'],
  });

  const [socket, setSocket] = useState(() => io(URL, generateSocketIObody(accessToken)));
  const [isConnected, setIsConnected] = useState(socket.connected);

  useEffect(() => {
    if (socket.connected) {
      socket.disconnect();
    }

    if (isLoggedIn) {
      setSocket(io(URL, generateSocketIObody(accessToken)));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  useEffect(() => {
    if (!isFirstRender) {
      socket.connect();
    }

    const onConnect = () => {
      setIsConnected(true);
    };

    const onDisconnect = () => {
      setIsConnected(false);
    };

    const onExportEvent = (value: ExportEvent) => {
      dispatch(addToEvents(value));
    };

    const onContainerRoomEvent = (value: WaitingQueueState) => {
      dispatch(getWaitingRoomData(value));
    };

    const onWaitingRoomChange = (value: DashboardContainerBoxWaitingRoomChange) => {
      dispatch(updateQueueOnDashboardContainerBox(value));
    };

    const onBagDeposited = (value: DashboardContainerDoorBagDepositedChange) => {
      dispatch(updateBagsDepositedOnScanner(value));
    };

    const onContainerScannerStateAvailable = (value: ContainerScannerAvailabilityUpdate) => {
      dispatch(setContainerScannerAvailableData({ isAvailable: true, ...value }));
    };

    const onContainerScannerStateNotAvailable = (value: ContainerScannerAvailabilityUpdate) => {
      dispatch(setContainerScannerAvailableData({ isAvailable: false, ...value }));
    };

    const onContainerScannerWelcomeDropOffData = (value: ContainerScannerWelcomeDropOffData) => {
      dispatch(setContainerScannerWelcomeDropOffData(value));
    };

    const onContainerScannerKickOutUser = (value: ContainerScannerBaseUpdate) => {
      dispatch(setContainerScannerKickOutUserData(value));
    };

    const onContainerScannerStateInProgress = (value: ContainerScannerStateUpdateIncoming) => {
      dispatch(setContainerScannerStateUpdateData({ ...value, state: SCANNER_STATE.IN_USE }));
    };

    const onContainerScannerStateWaitingFroDrop = (value: ContainerScannerStateUpdateIncoming) => {
      dispatch(
        setContainerScannerStateUpdateData({ ...value, state: SCANNER_STATE.WAITING_FOR_DROP }),
      );
    };

    const onContainerScannerStateReadyToDropData = (value: ContainerScannerStateUpdateIncoming) => {
      dispatch(
        setContainerScannerStateUpdateData({ ...value, state: SCANNER_STATE.READY_TO_DROP }),
      );
    };

    const onContainerScannerStateBagDroppedData = (value: ContainerScannerStateUpdateIncoming) => {
      dispatch(setContainerScannerStateUpdateData({ ...value, state: SCANNER_STATE.DROPPED }));
    };

    const onContainerScannerResponseErrorData = (data: ContainerScannerResponseError) => {
      toast.error(
        i18n.t('containerBoxes.containerResponseError', {
          command: data.command,
          description: data.description,
        }),
      );
    };

    socket.on(EVENTS.CONNECT, onConnect);
    socket.on(EVENTS.DISCONNECT, onDisconnect);
    socket.on(EVENTS.EXPORT, onExportEvent);
    socket.on(EVENTS.QUEUE_UPDATE, onContainerRoomEvent);
    socket.on(EVENTS.WAITING_ROOM_CHANGE, onWaitingRoomChange);
    socket.on(EVENTS.BAG_DEPOSITED, onBagDeposited);
    socket.on(EVENTS.WELCOME_DROPOFF, onContainerScannerWelcomeDropOffData);
    socket.on(EVENTS.KICK_OUT_CONTAINER_ROOM, onContainerScannerKickOutUser);
    socket.on(EVENTS.DEPOSIT_IN_PROGRESS, onContainerScannerStateInProgress);
    socket.on(EVENTS.WAITING_FOR_DROP, onContainerScannerStateWaitingFroDrop);
    socket.on(EVENTS.SCANNER_NOT_AVAILABLE, onContainerScannerStateNotAvailable);
    socket.on(EVENTS.SCANNER_AVAILABLE, onContainerScannerStateAvailable);
    socket.on(EVENTS.READY_TO_DROP, onContainerScannerStateReadyToDropData);
    socket.on(EVENTS.DROPPED, onContainerScannerStateBagDroppedData);
    socket.on(EVENTS.CONTAINER_RESPONSE_ERROR, onContainerScannerResponseErrorData);
    return () => {
      socket.off(EVENTS.CONNECT, onConnect);
      socket.off(EVENTS.DISCONNECT, onDisconnect);
      socket.off(EVENTS.EXPORT, onExportEvent);
      socket.off(EVENTS.QUEUE_UPDATE, onContainerRoomEvent);
      socket.off(EVENTS.WAITING_ROOM_CHANGE, onWaitingRoomChange);
      socket.off(EVENTS.BAG_DEPOSITED, onBagDeposited);
      socket.off(EVENTS.WELCOME_DROPOFF, onContainerScannerWelcomeDropOffData);
      socket.off(EVENTS.KICK_OUT_CONTAINER_ROOM, onContainerScannerKickOutUser);
      socket.off(EVENTS.DEPOSIT_IN_PROGRESS, onContainerScannerStateInProgress);
      socket.off(EVENTS.WAITING_FOR_DROP, onContainerScannerStateWaitingFroDrop);
      socket.off(EVENTS.SCANNER_NOT_AVAILABLE, onContainerScannerStateNotAvailable);
      socket.off(EVENTS.SCANNER_AVAILABLE, onContainerScannerStateAvailable);
      socket.off(EVENTS.READY_TO_DROP, onContainerScannerStateReadyToDropData);
      socket.off(EVENTS.DROPPED, onContainerScannerStateBagDroppedData);
      socket.off(EVENTS.CONTAINER_RESPONSE_ERROR, onContainerScannerResponseErrorData);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  return { isConnected };
};
