/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'config/store';
import { WAITING_LIST_STATUS } from 'modules/container-boxes/types';
import {
  ContainerScannersState,
  ContainerScannerBaseUpdate,
  ContainerScannerStateUpdate,
  ContainerScannerWelcomeDropOffData,
  SCANNER_STATE,
  ContainerScannerItemStateItem,
  ContainerScannerAvailableData,
} from 'modules/devices';

const initialState: ContainerScannersState = {
  data: [],
  shouldBeUpdated: false,
  waitingListStatus: WAITING_LIST_STATUS.OPEN,
};

const handleContainerScanners = (
  state: ContainerScannersState,
  { payload }: PayloadAction<ContainerScannerItemStateItem[]>,
) => {
  state.data = payload;
};

const handleContainerScannerAvailableData = (
  state: ContainerScannersState,
  { payload }: PayloadAction<ContainerScannerAvailableData>,
) => {
  state.waitingListStatus = payload.waitingListStatus ?? state.waitingListStatus;
  state.data = state.data.map((device) => {
    if (device.deviceId === payload.deviceId) {
      return {
        ...device,
        containerScannerState: {
          deviceId: device.deviceId,
          state: payload.isAvailable
            ? SCANNER_STATE.AVAILABLE_FOR_CONNECTION
            : SCANNER_STATE.NOT_AVAILABLE,
        },
        bagUIds: [],
      };
    }

    return device;
  });
};

const handleContainerScannerWelcomeDropOffData = (
  state: ContainerScannersState,
  { payload }: PayloadAction<ContainerScannerWelcomeDropOffData>,
) => {
  state.data = state.data.map((device) => {
    if (device.deviceId === payload.deviceId) {
      return {
        ...device,
        containerScannerState: {
          deviceId: device.deviceId,
          state: SCANNER_STATE.RESERVED_FOR_USE,
          activeBagHandoverSession: payload.session,
        },
        bagUIds: [],
        timer: payload.timer,
      };
    }

    return device;
  });
};

const handleContainerScannerKickOutUserData = (
  state: ContainerScannersState,
  { payload }: PayloadAction<ContainerScannerBaseUpdate>,
) => {
  state.data = state.data.map((device) => {
    if (device.containerScannerState?.activeBagHandoverSession?._id === payload.bagSessionId) {
      return {
        ...device,
        containerScannerState: {
          deviceId: device.deviceId,
          state: SCANNER_STATE.AVAILABLE_FOR_CONNECTION,
        },
        bagUIds: [],
      };
    }

    return device;
  });
};

const handleContainerScannerStateUpdateData = (
  state: ContainerScannersState,
  { payload }: PayloadAction<ContainerScannerStateUpdate>,
) => {
  let hasStateBeenUpdated = false;
  state.data = state.data.map((device, index) => {
    const isSameDevice = device.deviceId === payload.deviceId;
    const isSameSession =
      state.data[index]?.containerScannerState?.activeBagHandoverSession?._id ===
      payload.bagSessionId;

    const currentTimer = state.data[index].timer;
    const currentBagUIds = state.data[index].bagUIds ?? [];

    const bagUIds = payload.bagUId ? [...currentBagUIds, payload.bagUId] : currentBagUIds;

    if (isSameDevice && isSameSession && state.data[index]?.containerScannerState) {
      hasStateBeenUpdated = true;
      return {
        ...device,
        containerScannerState: {
          ...state.data[index]?.containerScannerState,
          state: payload.state,
          deviceId: device.deviceId,
        },
        timer: payload.timer ?? currentTimer,
        bagUIds,
      };
    }

    return device;
  });

  state.shouldBeUpdated = !hasStateBeenUpdated;
};

const handleWaitingListStatusChange = (
  state: ContainerScannersState,
  { payload }: PayloadAction<WAITING_LIST_STATUS>,
) => {
  state.waitingListStatus = payload;
};

export const containerScannersSlice = createSlice({
  name: 'containerScanners',
  initialState,
  reducers: {
    setContainerScannersData: handleContainerScanners,
    setContainerScannerAvailableData: handleContainerScannerAvailableData,
    setContainerScannerWelcomeDropOffData: handleContainerScannerWelcomeDropOffData,
    setContainerScannerKickOutUserData: handleContainerScannerKickOutUserData,
    setContainerScannerStateUpdateData: handleContainerScannerStateUpdateData,
    setContainerScannersBeUpdatedShouldBeUpdated: (state, { payload }: PayloadAction<boolean>) => {
      state.shouldBeUpdated = payload;
    },
    setWaitingListStatus: handleWaitingListStatusChange,
  },
});

export const containerScannersReducer = containerScannersSlice.reducer;

export const {
  setContainerScannersData,
  setContainerScannerAvailableData,
  setContainerScannerWelcomeDropOffData,
  setContainerScannerKickOutUserData,
  setContainerScannerStateUpdateData,
  setContainerScannersBeUpdatedShouldBeUpdated,
  setWaitingListStatus,
} = containerScannersSlice.actions;

export const selectContainerScanners = (state: RootState) => state.containerScanners.data;

export const selectContainerScannersShouldBeUpdated = (state: RootState) =>
  state.containerScanners.shouldBeUpdated;

export const selectContainerScannersWaitingListStatus = (state: RootState) =>
  state.containerScanners.waitingListStatus;
